
export default function useForms(){

  const login = {
    email: 'apptoken@trememote.com.ar',
    password: "123456"
  }
  
  return {
    login,

  }
}