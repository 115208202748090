import {post} from '@/services/API_POA_BACKEND'

export default {
  state: {
    
  },
  mutations: {
    
  },
  actions: {
    createGaleriaPublica({ getters, commit},form) {
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
          post(`galeriaPublica`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          }
          )
          .then(({data}) => {
            this.$toast.success(data.message);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
              commit("HIDE_LOADER");
            });
      });
    },
  },
  getters: {
    
  },
};
