import { createStore } from "vuex";
import loader from "./_loader"
import galeria from "./_galeria"
import auth from "./_auth"
import mail from "./_mail"

export default createStore({
  modules: {
    loader,
    auth,
    mail,
    galeria,
  },
});