import {post} from '@/services/API_POA_BACKEND'

export default {
  actions: {
   SendMailBack({commit}, form){
    commit("SHOW_LOADER");
    return new Promise((resolve)=>{
      post(`mail`, form).then((res)=> {
        resolve(res);
        })
      .then((res) => {
        resolve(res);
      })
      .catch(this.$errorHandler)
      .finally(() => {
          commit("HIDE_LOADER");
        });
      })
   } 
  }
}