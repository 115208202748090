import useForms from '@/composable/useForms';
import { post } from '@/services/API_POA_AUTH';

const {login} = useForms()

let form = {...login}

export default {
  state: () => ({
    token: null,
    time_dead: null,
  }),
  mutations: {
    SET_TOKEN(state, value) {
      if (value) localStorage.setItem("token", value);
      else localStorage.removeItem("token");
      state.token = value;
    },
    SET_TIMEDEAD(state, value) {
      if (value) localStorage.setItem("time_dead", value);
      else localStorage.removeItem("time_dead");
      state.time_dead = value;
    },
  },
  actions: {
    setCredentials({ commit }) {
      commit("SET_TOKEN", localStorage.getItem("token"));
      commit("SET_TIMEDEAD", localStorage.getItem("time_dead"))
    },
    login({ commit }) {
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
          post('login', form)
          .then(({data}) => {
            commit("SET_TOKEN", data.data.token);
            commit("SET_TIMEDEAD", data.data.user.time_dead);
            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
          commit("HIDE_LOADER")
        });
      });
    },
    logout({ commit, dispatch }) {
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        localStorage.removeItem("token");
        localStorage.removeItem("time_dead");
        commit("SET_TOKEN", null);
        commit("SET_TIMEDEAD", null);
        resolve();
      })
      .then(()=>{
        dispatch('login')
      })
    },
    
  },
  getters: {
    isLoggedIn(state) {
      return state.token !== null;
    },
    token(state) {
      return state.token;
    },
    time_dead(state) {
      return Date.now() < state.time_dead;
    },
  },
};
