<template>
  <header class="fondo-header">
    <div class="top-bar-inside">

    </div>
    <div class="header-top-bar"> 
        <div class="logo-poa-container">
          <video
          class="video-poa"
          src="../../assets/Logo_Poa.mp4"
          loop
          autoplay
          muted
          width="160"
          height="180"
        />
        <img class="img-poa" src="../../assets/Texto_Poa.png">
        </div>
      </div>
  </header>
</template>

<script setup>

</script>


<style scoped>
.fondo-header{
  background-image: url(../../assets/Fondo_Piedra.png);
}

.top-bar-inside{
  background-color: #65c178;
  height: 40px;
  width: 100%;
}

.img-poa{
  width: 150px;
  margin-top: 1em;
}

.header-top-bar{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em;
  padding-bottom: 1.5em;
  align-content: center;
}

.logo-poa-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.video-poa{
  border-end-end-radius: 5.6em;
  border-end-start-radius: 5.6em;
  cursor: pointer;
}

</style>
