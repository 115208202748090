<template>
  <footer class="fondo_footer">
      <div class="footer-bar">
       <div class="imagen-poa-footer">
         <img class="img-poa-footer" src="../../assets/Logo_Poa_Footer.png">
         <p>CLUB ARGENTINO DE CRIADORES DEL PERRO OVEJERO ALEMÁN</p>
          <p>ADOLFO ALSINA 1163, (1088) BUENOS AIRES, ARGENTINA</p>
       </div>
       <div>
        <h5>Contacto</h5>
        <div class="line-bottom">

        </div>
        <div>
          <ul>
            <li class="divider-li">
              <font-awesome-icon icon="fa-solid fa-phone" />
              <a
                >+54 (011) 4381-7368 / 9519 / 9596</a
              >
            </li>
            <div class="line-bottom-li"></div>
            <li>
              <font-awesome-icon icon="fa-solid fa-envelope" />
              <a class="">poa@clubpoa.com.ar</a>
            </li>
            <div class="line-bottom-li"></div>
            <li @click="ClubPoa">
              <font-awesome-icon icon="fa-solid fa-globe" />
              <a >www.clubpoa.org.ar</a>
            </li>
            <div class="line-bottom-li"></div>
            <li @click="facebookPoa">
              <font-awesome-icon icon="fa-brands fa-facebook" />
              <a >Facebook POA Seguinos..</a>
            </li>
            <div class="line-bottom-li"></div>
            <li @click="instagramPoa">
              <font-awesome-icon icon="fa-brands fa-instagram" />
              <a>Instragram POA Seguinos...</a>
            </li>
          </ul>
        </div>
       </div>
      </div>
      <div class="footer-inside">
        <p class="text-CopyRigth">Copyright Club Poa. All Rights Reserved</p>
      </div>
  </footer>
</template>

<script setup>
const facebookPoa = () => {
  window.open(`https://www.facebook.com/profile.php?id=100068426351096`)
}
const instagramPoa = () => {
  window.open(`https://www.instagram.com/club_poa/`)
}

const ClubPoa = () => {
  window.open(`http://www.clubpoa.org.ar`)
}
</script>


<style scoped>
.fondo_footer{
  background-color: #222222;
  display: flex;
  flex-direction: column;
}

.imagen-poa-footer{
  text-align: center;
  max-width: 350px;
}

.footer-bar{
  margin-top: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer-inside{
  display: flex;
  justify-content: flex-end;
  background-color: #333333;
  height: 40px;
  width: 100%;
}
.text-CopyRigth{
  margin: 0.5em 3em 1em 0;
}
.img-poa-footer{
  border-radius: 0.25em;
}
</style>
