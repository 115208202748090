
export const config =  {
  // NODE_ENV
  node_env: process.env.NODE_ENV === 'production',
  // URLS APIS
  auth_url: process.env.VUE_APP_AUTH_URL || null,
  backend_url: process.env.VUE_APP_BACKEND_URL || null,
  fotos_poa: process.env.VUE_FOTOS_POA_URL || 'http://localhost',
  //PORTS APP
  port: process.env.PORT || 3000, 
};