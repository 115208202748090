import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VCalendar from 'v-calendar';
import "v-calendar/dist/style.css";

import "./assets/scss/style.scss";
import VueModal from './components/Modal'

import toast from "@/utils/toast";
import errorHandler from "@/utils/errorHandler";


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faPhone, faEnvelope, faGlobe  } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faPhone,faEnvelope,faGlobe, faFacebook, faInstagram)

createApp(App)
.use(store)
.use(router)
.use(VueModal)
.use(toast)
.use(errorHandler)
.use(VCalendar, {})
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
