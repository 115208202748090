<template>
  <SpinnerLoaderV2 v-if="store.getters.loader_active" />
  <header-top-bar/>
  <router-view />
  <footer-bar/>
</template>

<script setup>
import HeaderTopBar from './views/components/HeaderTopBar.vue'
import FooterBar from './views/components/FooterBar.vue'
import SpinnerLoaderV2 from '@/components/SpinnerV2.vue'


import { useStore } from "vuex";
import {onBeforeMount} from 'vue';
const store = useStore();

onBeforeMount (async() => {
  await store.dispatch('setCredentials')
  if(!store.getters.isLoggedIn || !store.getters.time_dead){
    store.dispatch('logout')
  }
})
</script>

<style>

</style>
