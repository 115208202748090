import axios from "axios";
import {config} from "../config/config";

const axiosService = axios.create({
  baseURL: config.auth_url,
});

export const get = (path, params) => {
  return axiosService.get(path, params);
};

export const post = (path, body) => {
 return axiosService.post(path, body);
};

export const put = (path, body) => {
  return axiosService.put(path, body);
};

export const patch = (path, body) => {
  return axiosService.patch(path, body);
}

export const del = (path) => {
  return axiosService.delete(path);
};
